import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';
import Button from '../../../atoms/buttons/Button';
import Icon from '../../../atoms/icons/Icon';

const AtCommunicationActiveLayout = ({ intl, ...props }) => {
  const [atConfiguredType] = useState(props.atConfiguredType);

  const messagesKeys = {
    auto: {
      title: 'atCommunicationActiveAutoTitle',
      description: 'atCommunicationActiveAutoDescription',
      billingDocumentsDescription: 'atCommunicationMethodAutoDescription',
      transportDocumentsDescription: 'atCommunicationMethodAutoDescription',
    },
    guides: {
      title: 'atCommunicationActiveGuidesOnlyTitle',
      description: 'atCommunicationActiveGuidesOnlyDescription',
      billingDocumentsDescription: 'atCommunicationMethodManualDescription1',
      transportDocumentsDescription: 'atCommunicationMethodAutoDescription',
    },
    manual: {
      title: 'atCommunicationActiveManualTitle',
      description: 'atCommunicationActiveManualDescription',
      billingDocumentsDescription: 'atCommunicationMethodManualDescription1',
      transportDocumentsDescription: 'atCommunicationMethodManualDescription2',
    },
  };

  return (
    <div className='col-12 col-lg-9 ps-md-0 --at-communication-active'>
      <div className='d-flex flex-column w-100'>
        <div className='col-12 px-0 mb-4'>
          <Paragraph className='text-header h2'>
            {intl.messages['atCommunicationTitle']}
          </Paragraph>

          <Paragraph className='text-paragraph mt-3 mb-2'>
            <Span className='d-block'>
              {intl.messages['atCommunicationActiveDescription']}
            </Span>
          </Paragraph>
          <div className='sep-light mt-2'></div>
        </div>
        <div className='col-12 px-0 mt-4 info-banner '>
          <div className='d-flex flex-column justify-content-start info-container'>
            <div className='d-flex flex-row align-items-center info-header'>
              <div className='justify-content-center'>
                <Icon className='icon fas fa-circle-check'></Icon>
              </div>
              <div className='d-flex flex-column justify-content-start col-6 col-sm-8 col-lg-9 header-content'>
                <Paragraph className='text-header h4 m-0'>
                  {intl.messages[messagesKeys[atConfiguredType].title]}
                </Paragraph>
                <Paragraph className='text-paragraph m-0'>
                  <Span className='d-block'>
                    {intl.messages[messagesKeys[atConfiguredType].description]}
                  </Span>
                </Paragraph>
              </div>
            </div>
            <div className='d-flex flex-column info-content mb-4'>
              <div className='d-flex flex-column justify-content-start'>
                <Paragraph className='text-header h5 m-0'>
                  {intl.messages['atCommunicationActiveBillingDocuments']}
                </Paragraph>
                <Paragraph className='text-paragraph mt-1'>
                  <Span className='d-block'>
                    {
                      intl.messages[
                        messagesKeys[atConfiguredType]
                          .billingDocumentsDescription
                      ]
                    }
                  </Span>
                </Paragraph>
              </div>
              <div className='d-flex flex-column justify-content-start mt-4'>
                <Paragraph className='text-header h5 m-0'>
                  {
                    intl.messages[
                      'atCommunicationActiveTransportationDocuments'
                    ]
                  }
                </Paragraph>
                <Paragraph className='text-paragraph mt-1'>
                  <Span className='d-block'>
                    {
                      intl.messages[
                        messagesKeys[atConfiguredType]
                          .transportDocumentsDescription
                      ]
                    }
                  </Span>
                </Paragraph>
              </div>
            </div>
            <div className='d-flex col-12 px-0 mt-4'>
              <div className='d-flex w-100 flex-column flex-sm-row justify-content-end'>
                <Button
                  className='button button-outline ms-auto ms-sm-0'
                  id={'save_button'}
                  label={intl.messages['atCommunicationctiveButton']}
                  onClick={() => {
                    props.setCurrentPage('AtCommunicationLayout');
                  }}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(AtCommunicationActiveLayout);
