import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';
import Button from '../../../atoms/buttons/Button';
import Input from '../../../atoms/inputs/Input';
import RadioButtonLabel from '../../../molecules/labels/RadioButtonLabel';
import Icon from '../../../atoms/icons/Icon';
import InputPassword from '../../../molecules/inputs/InputPassword';

const AtCommunicationSetupLayout = ({ intl, ...props }) => {
  const [contactFields, setContactFields] = useState(props.contactFields);
  const [submitting] = useState(false);

  useEffect(() => {
    setContactFields(props.contactFields);
  }, [props.contactFields]);

  return (
    <div className='col-12 col-lg-9 ps-md-0 --at-communication-setup'>
      <div className='d-flex flex-column w-100'>
        <div className='col-12 px-0 mb-4'>
          <Paragraph className='text-header h2'>
            {intl.messages['atCommunicationSetupTitle']}
          </Paragraph>
          <Paragraph className='text-paragraph mt-3 mb-2'>
            <Span className='d-block'>
              {intl.messages['atCommunicationSetupDescription']}
            </Span>
          </Paragraph>
          <div className='sep-light mt-2'></div>
        </div>
        <div className='mt-4'>
          <div className='col-12 px-0'>
            <Paragraph className='text-header h3'>
              {intl.messages['atCommunicationSetupFieldsTitle']}
            </Paragraph>
            <Paragraph className='text-paragraph mt-3 mb-2'>
              <Span className='d-block'>
                {intl.messages['atCommunicationSetupFieldsDescription']}
              </Span>
            </Paragraph>
          </div>
          <div className='form-container d-flex flex-column w-100 mt-0'>
            <div className='form-container d-flex flex-wrap w-100 mt-0'>
              <div className='col-12 col-md-6 selector-container mt-4'>
                <div className='selector-wrapper'>
                  <label className='text-label mb-3'>
                    {intl.messages['taxDataFiscalId']}
                  </label>
                  <Input
                    data-testid='fiscal-id-field'
                    id='fiscal_id'
                    type='text'
                    value={contactFields.fiscal_id}
                    onChange={(e) =>
                      props.onChangeField('fiscal_id', e.target.value)
                    }
                    disabled={!props.canChangeNif || submitting}
                  />
                </div>
              </div>
              <div className='col-12 col-md-6 selector-container mt-4'>
                <div className='selector-wrapper'>
                  <label className='text-label mb-3'>
                    {intl.messages['postalCode']}
                  </label>
                  <Input
                    data-testid='address-field'
                    id='postal_code'
                    type='text'
                    value={contactFields.postal_code}
                    onChange={(e) => props.handlePostalCodeChange(e)}
                    disabled={submitting}
                  />
                  {props.postalCodeError && (
                    <div
                      data-testid='postal-code-error'
                      className='input-error'
                      id='postal_code_error'
                    >
                      <Icon className='icon fas fa-circle-exclamation' />
                      {intl.messages['invalidPostalCodeFormat']}
                    </div>
                  )}
                </div>
              </div>
              <div className='col-12 col-md-6 selector-container mt-4'>
                <div className='selector-wrapper'>
                  <label className='text-label mb-3'>
                    {intl.messages['address']}
                  </label>
                  <Input
                    data-testid='address-field'
                    id='address'
                    type='text'
                    value={contactFields.address}
                    onChange={(e) =>
                      props.onChangeField('address', e.target.value)
                    }
                    disabled={submitting}
                  />
                </div>
              </div>
              <div className='col-12 col-md-6 selector-container mt-4'>
                <div className='selector-wrapper'>
                  <label className='text-label mb-3'>
                    {intl.messages['city']}
                  </label>
                  <Input
                    data-testid='city-field'
                    id='city'
                    type='text'
                    value={contactFields.city}
                    onChange={(e) =>
                      props.onChangeField('city', e.target.value)
                    }
                    disabled={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mb-4'>
          <div className='col-12 px-0'>
            <Paragraph className='text-header h3'>
              {intl.messages['atCommunicationSetupAtUserTitle']}
            </Paragraph>
            <Paragraph className='text-paragraph mt-3 mb-2'>
              <Span className='d-block'>
                {intl.messages['atCommunicationSetupAtUserDescription']}
              </Span>
            </Paragraph>
            <div className='sep-light mt-2'></div>
          </div>
          <div className='d-flex flex-column w-100 mt-4 mb-4'>
            <div className='d-flex flex-row flex-wrap w-100 justify-content-between'>
              <div className='col-6 px-0'>
                <Paragraph className='text-header h5'>
                  {intl.messages['atCommunicationSetupSubUserExplainTitle']}
                </Paragraph>
                <Paragraph className='text-paragraph mt-2 mb-2'>
                  <Span className='d-block'>
                    {
                      intl.messages[
                        'atCommunicationSetupSubUserExplainDescription'
                      ]
                    }
                  </Span>
                </Paragraph>
              </div>
              <div className='selector-wrapper col-6'>
                <label className='text-label mb-3'>
                  {intl.messages['atCommunicationSetupAtSubUser']}
                </label>
                <Input
                  className='w-100'
                  data-testid='postal-code-field'
                  id='at_subuser'
                  type='text'
                  value={props.atSetupFields.at_subuser}
                  onChange={(e) =>
                    props.onChangeAtField('at_subuser', e.target.value)
                  }
                  disabled={false}
                />
              </div>
            </div>
            <div className='d-flex flex-row flex-wrap w-100 justify-content-between mt-4'>
              <div className='col-6 px-0'>
                <Paragraph className='text-header h5'>
                  {intl.messages['atCommunicationSetupPasswordExplainTitle']}
                </Paragraph>
                <Paragraph className='text-paragraph mt-2 mb-2'>
                  <Span className='d-block'>
                    {
                      intl.messages[
                        'atCommunicationSetupPasswordExplainDescription'
                      ]
                    }
                  </Span>
                </Paragraph>
              </div>
              <div className='selector-wrapper col-6'>
                <label className='text-label mb-3'>
                  {intl.messages['atCommunicationSetupAtPassword']}
                </label>
                <InputPassword
                  className='input-icon --right'
                  inputClassName='w-100'
                  data-testid='postal-code-field'
                  id='at_password'
                  type='text'
                  value={props.atSetupFields.at_password}
                  onChange={(e) =>
                    props.onChangeAtField('at_password', e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {props.atConfigured !== true &&
          props.communicationMethod !== 'guides' && (
            <div className='mb-2'>
              <div className='col-12 px-0'>
                <Paragraph className='text-header h3'>
                  {
                    intl.messages[
                      'atCommunicationSetupRetroactiveCommunicationTitle'
                    ]
                  }
                </Paragraph>
                <Paragraph className='text-paragraph mt-3 mb-2'>
                  <Span className='d-block'>
                    {
                      intl.messages[
                        'atCommunicationSetupRetroactiveCommunicationDescription'
                      ]
                    }
                  </Span>
                </Paragraph>
                <div className='sep-light mt-2'></div>
              </div>
              <div className='d-flex flex-column flex-sm-row w-100 mt-4 justify-content-start'>
                <div className='d-flex col-4'>
                  <div className='option-container'>
                    <RadioButtonLabel
                      id='current_year_communication'
                      name='current_year_communication'
                      className='checkbox round'
                      value={'only_this_year'}
                      checked={
                        props.atSetupFields.retroactive_communication_option ===
                        'only_this_year'
                      }
                      onChange={() => {
                        props.onChangeAtField(
                          'retroactive_communication_option',
                          'only_this_year'
                        );
                      }}
                    >
                      {
                        'atCommunicationSetupRetroactiveCommunicationOnlyThisYear'
                      }
                    </RadioButtonLabel>
                  </div>
                </div>
                <div className='d-flex col-4'>
                  <div className='option-container'>
                    <RadioButtonLabel
                      id='current_and_last_year_communication'
                      name='current_and_last_year_communication'
                      className='checkbox round'
                      value={'this_year_and_last_year'}
                      checked={
                        props.atSetupFields.retroactive_communication_option ===
                        'this_year_and_last_year'
                      }
                      onChange={() => {
                        props.onChangeAtField(
                          'retroactive_communication_option',
                          'this_year_and_last_year'
                        );
                      }}
                    >
                      {
                        'atCommunicationSetupRetroactiveCommunicationPreviousYears'
                      }
                    </RadioButtonLabel>
                  </div>
                </div>
              </div>
            </div>
          )}

        <div className='d-flex col-12 px-0 mt-4'>
          <div className='d-flex w-100 flex-column flex-sm-row justify-content-end'>
            <Button
              className='button button-secondary me-4 ms-sm-0'
              id={'save_button'}
              label={intl.messages['atCommunicationSetupBack']}
              onClick={() => {
                props.setCurrentPage('AtCommunicationLayout');
              }}
              disabled={false}
            />
            <Button
              className='button button-primary ms-auto ms-sm-0'
              id={'save_button'}
              label={intl.messages['atCommunicationSetupSend']}
              onClick={() => {
                props.onSetupSubmit();
              }}
              disabled={props.isSetupSaveDisabled() || props.postalCodeError}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(AtCommunicationSetupLayout);
