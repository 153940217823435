import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { injectIntl } from 'react-intl';
import AtCommunicationLayout from './AtCommunicationLayout';
import AtCommunicationSetupLayout from './AtCommunicationSetupLayout';
import AtCommunicationActiveLayout from './AtCommunicationActiveLayout';
import Menu, { MENU_CATEGORY } from '../../../organisms/menu/Menu';
import { ContactService } from '../../../../services';
import HandleCallResultModal from '../../feedback/modal/HandleCallResultModal';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

const initialContactFields = {
  fiscal_id: '',
  address: '',
  city: '',
  postal_code: '',
};

const initialAtSetupFields = {
  at_subuser: '',
  at_password: '',
  communication_type: '',
  retroactive_communication_option: '',
};

const AtCommunicationMainLayout = ({ props }) => {
  const { appState } = useContext(AppContext);
  const [contactFields, setContactFields] = useState(initialContactFields);
  const [atSetupFields, setAtSetupFields] = useState(() => ({
    ...initialAtSetupFields,
    retroactive_communication_option: 'only_this_year',
  }));
  const [accountSettings, setAccountSettings] = useState({});
  const [currentPage, setCurrentPage] = useState('');
  const [atConfiguredType, setAtConfiguredType] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [callResponse, setCallResponse] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [callSucceeded, setCallSucceeded] = useState(false);
  const [canChangeNif, setCanChangeNif] = useState(true);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [communicationMethod, setCommunicationMethod] = useState('auto');
  const [atConfigured, setAtConfigured] = useState(false);

  useEffect(() => {
    const fetchAccountSettings = async () => {
      const { account } = await ContactService.getAccountSettings();
      setAccountSettings(account);
      setAtConfigured(account.at_configured);
      const contactKeys = Object.keys(initialContactFields);
      setContactFields((prev) => ({
        ...prev,
        ...contactKeys.reduce(
          (acc, key) => ({ ...acc, [key]: account[key] || '' }),
          {}
        ),
      }));
      setAtSetupFields((prev) => ({
        ...prev,
        at_subuser: account.at_subuser || '',
      }));

      setCanChangeNif(account.can_change_nif);
      if (account.at_configured === true) {
        if (account.at_communication_type === 'auto') {
          setCurrentPage('AtCommunicationActiveLayout');
          setAtConfiguredType('auto');
        } else if (account.at_communication_type === 'guides') {
          setCurrentPage('AtCommunicationActiveLayout');
          setAtConfiguredType('guides');
        } else {
          setCurrentPage('AtCommunicationActiveLayout');
          setAtConfiguredType('manual');
        }
      } else {
        setCurrentPage('AtCommunicationLayout');
      }
    };

    fetchAccountSettings();
  }, [appState.accountId]);

  const responseErrorParser = (response) => {
    if (response.error) {
      const parsed = parse(DOMPurify.sanitize(response.error));
      return parsed;
    } else if (response.errors) {
      const parsed = parse(DOMPurify.sanitize(response.errors[0]['error']));
      return parsed;
    } else {
      return false;
    }
  };

  const onMethodSelectedSubmit = async () => {
    onChangeAtField('communication_type', communicationMethod);
    if (['auto', 'guides'].includes(communicationMethod)) {
      setCurrentPage('AtCommunicationSetupLayout');
    } else if (communicationMethod === 'manual') {
      setSubmitting(true);
      setIsSubmitted(true);
      setCallResponse('atCommunicationMethodToManual');
      const response = await ContactService.setAtCommunicationMethod({
        at_communication: {
          communication_type: 'manual',
        },
      });
      setSubmitting(false);
      if (!responseErrorParser(response)) {
        setAtConfigured(true);
        setAtConfiguredType('manual');
        setCurrentPage('AtCommunicationActiveLayout');
        setCallResponse('atCommunicationMethodToManualSuccess');
        setCallSucceeded(true);
      } else {
        const parsed = responseErrorParser(response);
        setCallResponse(parsed);
        setCallSucceeded(false);
      }
    }
  };

  const onChangeField = (key, value) =>
    setContactFields({ ...contactFields, [key]: value });

  const onChangeAtField = (key, value) =>
    setAtSetupFields({ ...atSetupFields, [key]: value });

  const isSetupSaveDisabled = () => {
    const { address, city, fiscal_id } = contactFields;
    const { at_subuser, at_password } = atSetupFields;

    return (
      submitting ||
      !address ||
      !city ||
      !fiscal_id ||
      !at_subuser ||
      !at_password
    );
  };

  const handlePostalCodeChange = (e) => {
    const value = e.target.value;
    onChangeField('postal_code', value);
    setPostalCodeError(!/^\d{4}-\d{3}$/.test(value));
  };

  const onSetupSubmit = async () => {
    setSubmitting(true);
    setIsSubmitted(true);
    setCallResponse('taxDataProcessing');

    const changedFields = Object.keys(contactFields).reduce((acc, key) => {
      const value =
        typeof contactFields[key] === 'string'
          ? contactFields[key].trim()
          : contactFields[key];
      if (value !== accountSettings[key]) {
        acc[key] = value;
      }
      return acc;
    }, {});

    if (Object.keys(changedFields).length > 0) {
      const updateResponse =
        await ContactService.updateAccountSettings(changedFields);
      const updateError = responseErrorParser(updateResponse);
      if (updateError) {
        setCallResponse(updateError);
        setCallSucceeded(false);
        setAtSetupFields((prev) => ({ ...prev, at_password: '' }));
        setSubmitting(false);
        return;
      }
    }

    const atSetupResponse = await ContactService.setAtCommunicationMethod({
      at_communication: atSetupFields,
    });
    const methodError = responseErrorParser(atSetupResponse);
    if (methodError) {
      setCallResponse(methodError);
      setCallSucceeded(false);
    } else {
      setAtConfigured(true);
      setAtConfiguredType(atSetupFields.communication_type);
      setCurrentPage('AtCommunicationActiveLayout');
      setCallResponse(
        atSetupFields.communication_type === 'auto'
          ? 'atCommunicationMethodToAutoSuccess'
          : 'atCommunicationMethodToGuidesSuccess'
      );
      setCallSucceeded(true);
    }
    setAtSetupFields((prev) => ({ ...prev, at_password: '' }));
    setSubmitting(false);
  };

  return (
    <div id='main-content' className='container --main-at-communication'>
      <div className='row mx-0 justify-content-center'>
        {currentPage === 'AtCommunicationLayout' ? (
          <AtCommunicationLayout
            setCurrentPage={setCurrentPage}
            setCommunicationMethod={setCommunicationMethod}
            communicationMethod={communicationMethod}
            onMethodSelectedSubmit={onMethodSelectedSubmit}
          />
        ) : currentPage === 'AtCommunicationSetupLayout' ? (
          <AtCommunicationSetupLayout
            communicationMethod={communicationMethod}
            atConfigured={atConfigured}
            setCurrentPage={setCurrentPage}
            contactFields={contactFields}
            canChangeNif={canChangeNif}
            onChangeField={onChangeField}
            onChangeAtField={onChangeAtField}
            onSetupSubmit={onSetupSubmit}
            atSetupFields={atSetupFields}
            isSetupSaveDisabled={isSetupSaveDisabled}
            handlePostalCodeChange={handlePostalCodeChange}
            postalCodeError={postalCodeError}
          />
        ) : currentPage === 'AtCommunicationActiveLayout' ? (
          <AtCommunicationActiveLayout
            setCurrentPage={setCurrentPage}
            atConfiguredType={atConfiguredType}
          />
        ) : (
          <div className='col-12 col-lg-9 ps-md-0'></div>
        )}
        <div className='col-lg-3 pe-md-0'>
          <Menu {...props} activeCategory={MENU_CATEGORY.AT_COMUNICATION} />
        </div>
      </div>
      {isSubmitted && (
        <HandleCallResultModal
          data-testid='handle-call-result-modal'
          isLoading={submitting}
          succeeded={callSucceeded}
          message={callResponse}
          onClose={() => {
            setIsSubmitted(false);
            setCallResponse(null);
          }}
          showCloseButton
        />
      )}
    </div>
  );
};

export default injectIntl(AtCommunicationMainLayout);
