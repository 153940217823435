import RequestAdapter from '../adapters/RequestAdapter';
import { API_V3_PATH_BASE, V2_PATH_BASE } from '../constants';
import { ENDPOINTS } from '../enums/endpoints';

/**
 * Fetch a list of tax
 *
 * @function
 * @returns {object} JSON response
 */
const getTaxList = () => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.SETTINGS.TAX_LIST}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Get a list of items
 *
 * @function
 *
 * @param {object} params
 *
 * @returns {object} JSON response
 */
const getItemsList = async (params) => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.SETTINGS.ITEMS_LIST}?${params}`,
    method: 'GET',
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Get an item
 *
 * @function
 *
 * @param {object} id
 *
 * @returns {object} JSON response
 */
const getItemById = async (id) => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.SETTINGS.ITEMS_LIST}/${id}`,
    method: 'GET',
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Delete one or more items
 *
 * @function
 *
 * @param {string} ids
 *
 * @returns {object} JSON response
 */
const deleteItems = async (ids) => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${
      ENDPOINTS.SETTINGS.ITEMS_LIST
    }/${ids.toString()}`,
    method: 'DELETE',
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Create Item
 *
 * @function
 *
 * @param {object} formData
 *
 * @returns {object} JSON response
 */
const createItem = async (formData) => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.SETTINGS.ITEMS_LIST}`,
    method: 'POST',
    body: JSON.stringify({
      item: { ...formData },
    }),
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Update Item
 *
 * @function
 *
 * @param {object} formData
 *
 * @returns {object} JSON response
 */
const updateItem = async (formData) => {
  const { id, ...data } = formData;
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.SETTINGS.ITEMS_LIST}/${id}`,
    method: 'PUT',
    body: JSON.stringify({
      item: { ...data },
    }),
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Fetch a list of currency
 *
 * @function
 * @returns {object} JSON response
 */
const getCurrencyList = () => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.SETTINGS.CURRENCIES}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Fetch a list of phone codes
 *
 * @function
 * @returns {object} JSON response
 */
const getPhoneCodeList = () => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.SETTINGS.PHONE_LIST}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

const getMessageTemplates = () => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.SETTINGS.MESSAGE_TEMPLATES}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

const updateMessageTemplate = (id, formData) => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.SETTINGS.MESSAGE_TEMPLATES}/${id}`,
    method: 'PUT',
    body: JSON.stringify({
      message_template: { ...formData },
    }),
  };

  return RequestAdapter.request(requestConfig);
};

const exportData = (dataType, format) => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.SETTINGS.EXPORT_DATA}?data_type=${dataType}&data_format=${format}`,
    method: 'POST',
  };
  return RequestAdapter.request(requestConfig);
};

export const SettingService = {
  getTaxList,
  getItemsList,
  deleteItems,
  createItem,
  updateItem,
  getItemById,
  getCurrencyList,
  getPhoneCodeList,
  getMessageTemplates,
  updateMessageTemplate,
  exportData,
};
