import React from 'react';
import { injectIntl } from 'react-intl';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';
import Image from '../../../atoms/images/Image';
import RadioButtonLabel from '../../../molecules/labels/RadioButtonLabel';
import Button from '../../../atoms/buttons/Button';

const AtCommunicationLayout = ({ intl, ...props }) => {
  return (
    <div className='col-12 col-lg-9 ps-md-0 --at-communication'>
      <div className='d-flex flex-column w-100'>
        <div className='col-12 px-0'>
          <Paragraph className='text-header h2'>
            {intl.messages['atCommunicationTitle']}
          </Paragraph>

          <Paragraph className='text-paragraph mt-3 mb-2'>
            <Span className='d-block'>
              {intl.messages['atCommunicationDescription']}
            </Span>
          </Paragraph>

          <div className='sep-light mt-2'></div>
        </div>
        <div className='mt-4'>
          <div className='col-12 px-0 info-banner '>
            <div className='d-flex flex-column flex-sm-row align-items-center info-banner-content'>
              <div className='col-6 col-sm-4 col-lg-3'>
                <Image
                  className='w-100'
                  src={
                    'https://invoicexpress-assets.s3.eu-west-1.amazonaws.com/invoicexpress_figure_pc.png'
                  }
                />
              </div>
              <div className='d-flex flex-column justify-content-start col-8'>
                <Paragraph className='text-paragraph mb-2'>
                  {intl.messages['atCommunicationText1']}
                </Paragraph>
                <Paragraph className='text-paragraph mt-2'>
                  <Span className='d-block'>
                    {intl.messages['atCommunicationText2']}
                  </Span>
                </Paragraph>
              </div>
            </div>
          </div>
          <div className='choose-method mt-4'>
            <Paragraph className='text-paragraph'>
              {intl.messages['atCommunicationChooseMehtod']}
            </Paragraph>
          </div>
          <div className='d-flex flex-column mt-4 option-container'>
            <div className='d-flex flex-column option-stack'>
              <div className='option-content'>
                <RadioButtonLabel
                  id='automatic_communication'
                  name='communication_method'
                  className='checkbox round'
                  value={'auto'}
                  checked={props.communicationMethod === 'auto'}
                  onChange={() => {
                    props.setCommunicationMethod('auto');
                  }}
                >
                  {'atCommunicationMethodAuto'}
                </RadioButtonLabel>
              </div>
              <div className='d-flex flex-column option-description '>
                <Paragraph className='text-paragraph'>
                  {intl.messages['atCommunicationMethodAutoDescription']}
                </Paragraph>
              </div>
            </div>
            <div className='d-flex flex-column option-stack'>
              <div className='option-content'>
                <RadioButtonLabel
                  id='manual_communication'
                  name='communication_method'
                  className='checkbox round'
                  value={'manual'}
                  checked={props.communicationMethod === 'manual'}
                  onChange={() => {
                    props.setCommunicationMethod('manual');
                  }}
                >
                  {'atCommunicationMethodManual'}
                </RadioButtonLabel>
              </div>
              <div className='d-flex flex-column option-description '>
                <Paragraph className='text-paragraph mb-2'>
                  {intl.messages['atCommunicationMethodManualDescription1']}
                </Paragraph>
                <Paragraph className='text-paragraph mt-2'>
                  {intl.messages['atCommunicationMethodManualDescription2']}
                </Paragraph>
              </div>
            </div>
            <div className='d-flex flex-column option-stack'>
              <div className='option-content'>
                <RadioButtonLabel
                  id='automatic_guides'
                  name='communication_method'
                  className='checkbox round'
                  value={'guides'}
                  checked={props.communicationMethod === 'guides'}
                  onChange={() => {
                    props.setCommunicationMethod('guides');
                  }}
                >
                  {'atCommunicationMethodGuidesOnly'}
                </RadioButtonLabel>
              </div>
              <div className='d-flex flex-column option-description '>
                <Paragraph className='text-paragraph'>
                  {intl.messages['atCommunicationMethodGuidesOnlyDescription']}
                </Paragraph>
              </div>
            </div>
          </div>
          <div className='d-flex col-12 px-0 mt-4'>
            <div className='d-flex w-100 flex-column flex-sm-row justify-content-end'>
              <Button
                className='button button-primary ms-auto ms-sm-0'
                id={'save_button'}
                label={intl.messages['confirmAction']}
                onClick={() => {
                  props.onMethodSelectedSubmit();
                }}
                disabled={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(AtCommunicationLayout);
